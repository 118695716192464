// Components
// const Swiper = require("swiper");
const Marquee = require("./components/Marquee");

// Initialize
window.addEventListener("DOMContentLoaded", () => {

    // // ---------------
    // // Swipers
    // // https://codepen.io/udovichenko/pen/LGeQae
    // // Adapted for vertical
    // let interleaveOffset = 0.5;

    // let listeners = {
    //     touchEnd: function () {

    //         this.el.scrollIntoView({
    //             behavior: "smooth",
    //             block: "center"
    //         });

    //     },
    //     progress: function () {

    //         let swiper = this;

    //         for (let i = 0; i < swiper.slides.length; i++) {

    //             if (swiper.slides[i].classList.contains("swiper-scrollable")) {

    //                 continue;

    //             }

    //             let slideProgress = swiper.slides[i].progress,
    //                 innerOffset = swiper.height * interleaveOffset,
    //                 innerTranslate = slideProgress * innerOffset;

    //             swiper.slides[i].firstChild.style.transform = `translate3d(0, ${innerTranslate}px, 0)`;
    //         }
    //     },
    //     touchStart: function () {

    //         let swiper = this;

    //         for (let i = 0; i < swiper.slides.length; i++) {

    //             swiper.slides[i].style.removeProperty("transition");

    //         }
    //     },
    //     setTransition: function (speed) {

    //         let swiper = this;

    //         for (let i = 0; i < swiper.slides.length; i++) {

    //             swiper.slides[i].style.transition = `${speed}ms`;
    //             swiper.slides[i].firstChild.style.transition = `${speed}ms`;

    //         }
    //     }
    // };

    // let swipers = [];

    // swipers.push(new Swiper(".swiper-main", {
    //     direction: "vertical",
    //     grabCursor: true,
    //     speed: 500,
    //     spaceBetween: 50,
    //     autoplay: {
    //         disableOnInteraction: true,
    //         delay: 5000
    //     },
    //     pagination: {
    //         el: ".swiper-pagination-main",
    //         clickable: true,
    //         dynamicBullets: true
    //     },
    //     watchSlidesProgress: true,
    //     touchReleaseOnEdges: true,
    //     keyboard: {
    //         enabled: true,
    //         onlyInViewport: true
    //     },
    //     // mousewheel: {
    //     //     enabled: true,
    //     //     forceToAxis: true,
    //     //     releaseOnEdges: true
    //     // },
    //     on: listeners
    // }));

    // let labels = document.querySelector(".swiper-nested .swiper-pagination").dataset.labels.split(",");

    // swipers.push(new Swiper(".swiper-nested", {
    //     direction: "vertical",
    //     grabCursor: true,
    //     speed: 500,
    //     spaceBetween: 50,
    //     nested: true,
    //     touchReleaseOnEdges: true,
    //     watchSlidesProgress: true,
    //     pagination: {
    //         el: ".swiper-pagination-nested",
    //         clickable: true,
    //         renderBullet: (index, cls) => `<span class="${cls}">${labels[index]}</span>`
    //     },
    //     on: {
    //         slideChange: function () {

    //             document.querySelector(".swiper-pagination-nested").dataset.currentIndex = this.realIndex;

    //         }
    //     }
    // }));

    // swipers.forEach(swiper => swiper.update());

    // let spaceUpdate = () => {

    //     swipers[0].params.spaceBetween = parseFloat(getComputedStyle(document.documentElement).fontSize) * 2.5;
    //     swipers[0].update();

    // }

    // window.addEventListener("resize", spaceUpdate);

    // spaceUpdate();


    // ---------------
    // Marquees
    document.querySelectorAll(".marquee").forEach(element => {

        new Marquee(element);

    });


});