require("element-remove");

module.exports = class {

    constructor(element) {

        this.element = element;
        this.text = element.textContent;

        this.container = document.createElement("div");
        this.container.classList.add("marquee-wrapper");

        element.textContent = "";

        this.OnResize();

        window.addEventListener("resize", this.OnResize.bind(this));

    }

    OnResize() {

        this.width = this.element.getBoundingClientRect().width;

        this.Update();

    }

    Update() {

        this.element.appendChild(this.container);

        this.container.style.removeProperty("width");

        this.container.childNodes.forEach(child => child.remove());

        let child = document.createElement("span");

        child.classList.add("marquee-content");

        child.textContent = this.text;
        child.dataset.text = this.text;

        this.container.appendChild(child);

        let width = child.getBoundingClientRect().width;

        this.container.style.width = `${width}px`;

        let iterations = Math.min(Math.ceil(this.width / width), 10);

        for (let i = 0; i < iterations; i++) {

            let clone = child.cloneNode(true);

            this.container.appendChild(clone);

            width += clone.getBoundingClientRect().width;

        }
    }
}